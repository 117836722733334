import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from '../../../config/theme';

const Heading = styled.div`
  color: #3c4858;
  margin-top: 0.625rem;
  min-height: auto;
  font-size: 16px;
  font-family: ${theme.fontFamily.heading};
  font-weight: ${theme.fontWeight.heading};
  text-align: center;
  text-decoration: none;
  a {
    color: #3c4858;
  }
  @media (max-width: 600px) {
    text-align: left;
  }
`;
const Title = styled.div`
  color: #3c4858;
  margin-top: 0.625rem;
  min-height: auto;
  font-weight: ${theme.fontWeight.heading};
  margin-bottom: 0.25rem;
  font-family: ${theme.fontFamily.heading};
  text-decoration: none;
  max-height: 57px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ProjectTitle = styled.div`
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: ${theme.fontWeight.body};
  color: #498fe0;
  font-family: ${theme.fontFamily.body};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  svg {
    font-size: 24px;
    color: #ee2737ff;
  }
`;
const ShortDescription = styled.div`
  margin: 10px 0px;
  min-height: 63px;
  span {
    color: ${theme.fontColor.body};
    font-size: 14px;
    text-align: left;
    font-weight: ${theme.fontWeight.body};
    max-height: 63px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: ${theme.fontFamily.body};
    @media (max-width: 930px) {
      white-space: normal;
    }
    @media (max-width: 600px) {
      text-align: left;
      min-height: auto;
    }
  }
`;

const TotalCost = styled.div`
  color: #3c4858;
  margin: 10px 0px;
  font-weight: ${theme.fontWeight.heading};
  font-family: ${theme.fontFamily.heading};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
    font-family: ${theme.fontFamily.heading};
    font-weight: ${theme.fontWeight.heading};
    display: block;
    svg {
      vertical-align: text-top;
      color: #498fe0;
      font-size: 18px;
    }
  }
`;

const SectorImage = styled.div`
  width: 100%;

  box-shadow: 0 2px 2px 0 rgb(141, 148, 157), 0 3px 5px 4px rgb(220, 220, 220),
    0 1px 5px 0 rgb(255, 255, 255);
  border-radius: 6px;
  cursor: pointer;
  margin-top: -30px;
  border-radius: 6px;
  transition: all 0.5s;
  overflow: hidden;
  img {
    max-width: 100%;
    height: 180px;
    margin-bottom: -10px;
    width: inherit;
  }
  .gatsby-image-wrapper {
    height: 175px;
  }
  &:hover {
    transform: translate3d(0, -30px, 0);
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const TableGridPagination = css`
  @media (max-width: 480px) {
    .MuiTablePagination-toolbar {
      display: block;
      width: 100%;
      text-align: center;
      padding-left: 0px;
      p.MuiTablePagination-caption {
        display: inline-block;
      }
      .MuiTablePagination-selectRoot {
        margin-right: 10px;
      }
      .makeStyles-root-513 {
        margin-left: 0px;
      }
    }
  }
`;
const SectorGrid = styled.div`
  cursor: pointer;
  margin-bottom: 80px;
  padding: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
  min-height: 300px;
  p {
    color: #3c4858;
    margin-top: 5px;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
    font-family: ${theme.fontFamily.heading};
    font-weight: ${theme.fontWeight.heading};
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    svg {
      vertical-align: text-top;
      color: #498fe0;
      font-size: 18px;
    }
    @media (max-width: 600px) {
      text-align: center;
    }
  }
  @media (max-width: 600px) {
    margin-top: 50px;
  }

  &:hover {
    .partnerImage {
      transition: all 0.5s;
      transform: translate3d(0, -35px, 0);
    }
  }
  transition: all 0.5s;
`;

const pt_20 = css`
  padding-top: 20px;
`;

const ProjectSource = styled.div`
  text-align: right;
  font-size: 0.75rem;
  font-weight: ${theme.fontWeight.heading};
  margin: 8px 0px;
  color: ${theme.fontColor.body};
  font-family: ${theme.fontFamily.heading};
  span {
    font-size: 12px;
    line-height: 1rem;
    cursor: pointer;
    font-family: ${theme.fontFamily.heading};
  }
  .MuiChip-root {
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgb(141, 148, 157), 0 3px 5px 4px rgb(220, 220, 220),
      0 1px 5px 0 rgb(255, 255, 255);
  }
`;

const SourcePlaceholder = styled.div`
  height: 24px;
  display: block;
`;

const titleClamp = css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 250px;
`;

const PartnerLogo = styled.div`
  width: 100%;
  box-shadow: 0 2px 2px 0 rgb(141, 148, 157), 0 3px 5px 4px rgb(220, 220, 220),
    0 1px 5px 0 rgb(255, 255, 255);
  border-radius: 6px;
  cursor: pointer;
  margin-top: -30px;
  border-radius: 6px;
  transition: all 0.5s;
  overflow: hidden;
  height: 180px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  background-color: #fff;
  img {
    max-width: 100%;
    max-height: 180px;
    height: auto;
    width: auto;
    margin-bottom: 0px;
  }
  .gatsby-image-wrapper {
    height: 180px;
  }
  &:hover {
    transform: translate3d(0, -30px, 0);
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const partnerImage = css`
  min-height: 260px;
  display: grid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 0px;
  border-radius: 4px;
  margin-top: -17px;
  background: #fff;
  transition: all 0.5s;
`;
const locationTitle = css`
  display: flex;
  align-items: center;
`;

export default {
  Heading,
  ShortDescription,
  SectorImage,
  TableGridPagination,
  SectorGrid,
  Title,
  TotalCost,
  ProjectTitle,
  pt_20,
  ProjectSource,
  SourcePlaceholder,
  titleClamp,
  PartnerLogo,
  partnerImage,
  locationTitle,
};
