import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import { Container } from 'layouts';
import { ThreeDots } from 'react-loader-spinner';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import useLocalStorage from '../shared/useLocalStorage';

import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../assets/jss/styles/components/dashboardStyle';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '../components/IndustrialParks/Table/Table';
import GridOn from '@material-ui/icons/GridOn';
import LocationOn from '@material-ui/icons/LocationOn';
import ViewList from '@material-ui/icons/ViewList';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import FilterList from '@material-ui/icons/FilterList';
import Slide from '@material-ui/core/Slide';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { FormControlLabel } from '@material-ui/core';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import ContentLoader from 'react-content-loader';
import FilterText from '../components/FilterText';
import projectStyles, { useStyles } from '../styles/projects';
import { useTranslation } from 'react-i18next';
import ProjectsSearch from '../components/ProjectsSearch.jsx';
import GridView from '../components/IndustrialParks/GridView';
import getHeaderLInks from '../shared/headerLinks.js';
import filterOfflineIndustrialParks from '../shared/filterOfflineIndustrialParks';
import Switch from '@material-ui/core/Switch';
import { create } from 'apisauce';
import MapWrapper from '../components/GoogleMap/MapWrapper.jsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });
const apiSauceInstanceMapBox = create({
  baseURL: Constants.mapBoxGeoCodingUrl,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const {
  // buttonSubmit,
  // buttonClear,
  buttonApply,
  // FilterHeading,
  // MobileFilter,
  filterItem,
  clearSection,
  GridSkeletonWrapper,
} = projectStyles;

const Skeleton = props => (
  <ContentLoader
    className="skeleton-svg"
    height={250}
    width={250}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="250" height="250" />
  </ContentLoader>
);

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((acc, param) => {
          let [keyValue, ...values] = param.split('::');
          let [key, value] = keyValue.split('=');
          if (value) {
            const paramValues = [value, ...values];
            acc[key] = (paramValues || []).map(value =>
              decodeURIComponent(value.replace(/\+/g, ' '))
            );
            return acc;
          }
          acc[key] = undefined;
          return [];
        }, {})
    : {};
};

const IndustrialParks = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const helmetLink = `${Constants.appUrl}/projects`;
  const { location, navigate } = props;

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks(
    'Industrial Parks',
    programLink,
    data.noLinks
  );

  const logos = pageContext.logos || [];

  const [showPartners, setShowPartners] = useState(false);
  const dataSectionRef = useRef(null);

  const useless = 0;
  useEffect(() => {
    setFavoriteProjects(favoriteProjects);
  }, [useless]);

  const { programName, pageHeader, headerLogo, headerLogoScroll, screenName } =
    (data || {}).node || {};
  const defaultProjectsForMapView = 999999;
  const styleClasses = useStyles();
  const [partners, setPartners] = useState([]);
  const [SEZs, setSEZs] = useState([]);
  const [loadMapViewData, setLoadMapViewData] = useState(false);
  const [loadPartnersMapViewData, setLoadPartnersMapViewData] = useState(false);
  const [loadMapViewDataInitial, setLoadMapViewDataInitial] = useState(false);
  const [filteredSEZs, setFilteredSEZs] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(
    getQueryStringParams(location.search || '')
  );
  const theme = useTheme();
  // const [costRange, setCostRange] = useState([0, 0]);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [investmentTypes, setInvestmentTypes] = useState([]);
  const [sdg, setSdg] = useState([]);
  const [source, setSource] = useState([]);
  const [industrialParksView, setIndustrialParksView] = useLocalStorage(
    'industrialParksView',
    'map',
    null,
    true
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [filterTab, setFilterTab] = useState(0);
  // const [loadMapViewData, setLoadMapViewData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [
    isIndustrialParksProcessing,
    setIsIndustrialParksProcessing,
  ] = useState(true);
  const [isInstitutionsProcessing, setIsInstitutionsProcessing] = useState(
    true
  );
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [filterMasterData, setFilterMasterData] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 8;
  const [pageSize, setPageSize] = useState(
    industrialParksView === 'map' ? defaultProjectsForMapView : itemsPerPage
  );
  const [sortBy, setSortBy] = useState('projectTitle');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sezCount, setSezCount] = useState(0);
  const [isCostRangeUpdated, setCostRangeUpdated] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [clearFilterSection, setClearFilterSection] = useState(false);
  const [filterSectionExpand, setFilterSectionExpand] = useState('');
  const [favoriteProjects, setFavoriteProjects] = useLocalStorage(
    `${data.node.programCode ? data.node.programCode : ''}Favorites`,
    []
  );
  const [offlineIndustrialParks, setOfflineIndustrialParks] = useLocalStorage(
    `${
      data.node.programCode ? data.node.programCode : ''
    }offlineIndustrialParks`,
    []
  );

  //Apply Button functionality
  const [countryApplied, setCountryApplied] = useState(false);
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    // if (!(partners.length > 0)) {
    setIsInstitutionsProcessing(true);
    apiSauceInstance
      .get(Constants.getAllInstitutions + `/${i18n.language}`)
      .then(partners => {
        if (partners && partners.ok) {
          setPartners([
            ...partners.data.map(ip => ({
              ...ip,
              coordinates: [ip.latitude, ip.longitude],
              countryID: ip.country.countryID,
              countryName: ip.country.countryName,
              isPartner: true,
            })),
          ]);
        }
        setIsInstitutionsProcessing(false);
      })
      .catch(error => {
        if (error) {
          setIsInstitutionsProcessing(false);
          // do something
        }
      });
    // }
  }, [i18n.language]);

  useEffect(() => {
    setIsProcessing(true);
    const filterParams = prepareFilterMasterParams(searchCriteria);
    getAllIndustrialParks(filterParams);
  }, [i18n.language]);

  useEffect(() => {
    setFilteredSEZs([]);
    setSearchCriteria(getQueryStringParams(location.search || ''));
  }, [location.search]);

  useEffect(() => {
    const filterParams = prepareFilterMasterParams(searchCriteria);
    const filteredSez =
      filterOfflineIndustrialParks(
        [
          ...SEZs.map(ip => ({
            ...ip,
            coordinates: [ip.latitude, ip.longitude],
            countryID: ip.country.countryID,
            countryName: ip.country.countryName,
          })),
          ...(showPartners ? partners || [] : []),
        ],
        { ...filterParams, Filters: searchCriteria },
        filterMasterData,
        favoriteProjects
      ) || [];
    setFilteredSEZs([...(filteredSez || [])]);
    setFilterValues(searchCriteria);
  }, [
    filterMasterData,
    searchCriteria,
    sortBy,
    sortOrder,
    pageSize,
    pageNumber,
    showPartners,
  ]);

  const getAllIndustrialParks = filterParams => {
    setIsIndustrialParksProcessing(true);
    apiSauceInstance
      .get(Constants.getAllIndustrialParks + `/${i18n.language}`)
      .then(industrialParks => {
        if (industrialParks && industrialParks.ok) {
          processIndustrialPark(industrialParks);
          syncOfflineIndustrialParks(industrialParks.data.items);
        } else {
          const Sez =
            filterOfflineIndustrialParks(
              [
                ...(offlineIndustrialParks ? offlineIndustrialParks : []),
                ...(showPartners ? partners || [] : []),
              ],
              { ...filterParams, Filters: searchCriteria },
              filterMasterData,
              favoriteProjects
            ) || [];
          processIndustrialPark({ data: Sez });
          setIsProcessing(false);
        }
        setIsIndustrialParksProcessing(false);
      })
      .catch(error => {
        if (error) {
          setIsIndustrialParksProcessing(false);
          // do something
        }
      });
  };

  const processIndustrialPark = industrialParks => {
    setSezCount(industrialParks.data.length);
    setSEZs(industrialParks.data);
    generateFilterMasterData(industrialParks.data);
    setLoadMapViewData(true);
    setLoadMapViewDataInitial(true);
  };

  const generateFilterMasterData = industrialParks => {
    var countries = [];
    industrialParks.forEach(ip => {
      countries.push({
        countryID: ip.country.countryID,
        countryName: ip.country.countryName,
      });
    });
    const filterMasterData = [];
    const tracker = new Map();
    for (const item of countries) {
      if (!tracker.has(item.countryID)) {
        tracker.set(item.countryID, true);
        filterMasterData.push({
          countryID: item.countryID,
          countryName: item.countryName,
        });
      }
    }
    filterMasterData.sort((a, b) => {
      return a.countryName.toLocaleUpperCase() >
        b.countryName.toLocaleUpperCase()
        ? 1
        : -1;
    });
    setFilterMasterData({
      COUNTRY: filterMasterData,
    });
    setIsProcessing(false);
  };

  useEffect(() => {
    if (loadMapViewData && filteredSEZs && filteredSEZs.length > 0) {
      Promise.all(
        filteredSEZs.map(async industrialPark => {
          if (
            industrialPark.countryName &&
            !(industrialPark.coordinates || [])[0] &&
            !(industrialPark.coordinates || [])[1]
          ) {
            const searchText = `${industrialPark.countryName} ${
              industrialPark.region ? industrialPark.region.regionName : ''
            } ${industrialPark.city ? industrialPark.city.cityName : ''}`;
            // const url = `${Constants.mapBoxGeoCodingUrl}/${industrialPark.country.countryCode}.json?access_token=${Constants.mapBoxApiKey}&autocomplete=true&types=country`;
            const url = `/${searchText}.json?access_token=${Constants.mapBoxApiKey}`;
            const mapCoordinates = await apiSauceInstanceMapBox.get(url);
            if (
              mapCoordinates.data &&
              mapCoordinates.data.features.length > 0
            ) {
              if (
                mapCoordinates.data.features[0].geometry &&
                mapCoordinates.data.features[0].geometry.coordinates
              ) {
                return {
                  ...industrialPark,
                  longitude:
                    mapCoordinates.data.features[0].geometry.coordinates[0] ||
                    0,
                  latitude:
                    mapCoordinates.data.features[0].geometry.coordinates[1] ||
                    0,
                  coordinates: (
                    mapCoordinates.data.features[0].geometry.coordinates || []
                  ).reverse(),
                };
              }
            }

            return { ...industrialPark, longitude: 0, latitude: 0 };
          } else {
            return industrialPark;
          }
        })
      )
        .then(industrialPark => {
          setLoadMapViewData(false);
          setFilteredSEZs(industrialPark);
        })
        .catch(() => {
          setLoadMapViewData(false);
        });
    }
  }, [filteredSEZs, loadMapViewData]);

  useEffect(() => {
    if (loadMapViewDataInitial && SEZs && SEZs.length > 0) {
      Promise.all(
        SEZs.map(async industrialPark => {
          if (
            industrialPark.country.countryName &&
            !industrialPark.longitude &&
            !industrialPark.latitude
          ) {
            const searchText = `${industrialPark.country.countryName} ${
              industrialPark.region ? industrialPark.region.regionName : ''
            } ${industrialPark.city ? industrialPark.city.cityName : ''}`;
            // const url = `${Constants.mapBoxGeoCodingUrl}/${industrialPark.country.countryCode}.json?access_token=${Constants.mapBoxApiKey}&autocomplete=true&types=country`;
            const url = `/${searchText}.json?access_token=${Constants.mapBoxApiKey}`;
            const mapCoordinates = await apiSauceInstanceMapBox.get(url);
            if (
              mapCoordinates.data &&
              mapCoordinates.data.features.length > 0
            ) {
              if (
                mapCoordinates.data.features[0].geometry &&
                mapCoordinates.data.features[0].geometry.coordinates
              ) {
                return {
                  ...industrialPark,
                  longitude:
                    mapCoordinates.data.features[0].geometry.coordinates[0] ||
                    0,
                  latitude:
                    mapCoordinates.data.features[0].geometry.coordinates[1] ||
                    0,
                };
              }
            }

            return { ...industrialPark, longitude: 0, latitude: 0 };
          } else {
            return industrialPark;
          }
        })
      )
        .then(industrialPark => {
          setSEZs(industrialPark);
          setLoadMapViewDataInitial(false);
        })
        .catch(() => {
          setLoadMapViewDataInitial(false);
        });
    }
  }, [SEZs, loadMapViewDataInitial]);

  useEffect(() => {
    if (loadPartnersMapViewData && partners && partners.length > 0) {
      Promise.all(
        partners.map(async partner => {
          if (
            partner.country.countryName &&
            !partner.longitude &&
            !partner.latitude
          ) {
            const searchText = `${partner.countryName} ${
              partner.region ? partner.region.regionName : ''
            } ${partner.city ? partner.city.cityName : ''}`;
            const url = `/${searchText}.json?access_token=${Constants.mapBoxApiKey}`;
            const mapCoordinates = await apiSauceInstanceMapBox.get(url);
            if (
              mapCoordinates.data &&
              mapCoordinates.data.features.length > 0
            ) {
              if (
                mapCoordinates.data.features[0].geometry &&
                mapCoordinates.data.features[0].geometry.coordinates
              ) {
                return {
                  ...partner,
                  longitude:
                    mapCoordinates.data.features[0].geometry.coordinates[0] ||
                    0,
                  latitude:
                    mapCoordinates.data.features[0].geometry.coordinates[1] ||
                    0,
                  coordinates: (
                    mapCoordinates.data.features[0].geometry.coordinates || []
                  ).reverse(),
                };
              }
            }
            return { ...partner, longitude: 0, latitude: 0 };
          } else {
            return partner;
          }
        })
      )
        .then(partner => {
          setLoadPartnersMapViewData(false);
          setPartners(partner);
        })
        .catch(() => {
          setLoadPartnersMapViewData(false);
        });
    }
  }, [partners, loadPartnersMapViewData]);

  useEffect(() => {
    if (clearFilterSection) {
      setClearFilterSection(false);
      filterClick('filter')();
    }
  }, [clearFilterSection]);

  const filterClick = (action, inputQuery = null) => () => {
    let params = {};
    let queryString = '';
    if (action === 'filter' || action === 'clearSearch') {
      params = {
        search: searchString && action === 'filter' ? searchString : '',
        Country: countries ? countries : [],
      };
      Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length === 0) {
          delete params[key];
        }
      });

      queryString =
        '?' +
        Object.keys(params)
          .map(key =>
            key === 'search'
              ? `${key}=${params[key]}`
              : `${key}=${params[key].reduce((acc, value) => {
                  return acc === '' ? `${acc}${value}` : `${acc}::${value}`;
                }, '')}`
          )
          .join('&');
      setOpenDialog(false);
    } else {
      // setCostRange([
      //   0,
      //   ((filterMasterData || {}).TOTALINVESTMENTCOST || {}).max || 999999,
      // ]);
      setCountries([]);
      setSectors([]);
      setInvestmentTypes([]);
      setSdg([]);
      setCostRangeUpdated(false);
      setSource([]);
      setSearchString('');
      setPageNumber(0);
    }
    setPageNumber(0);
    setPageSize(
      industrialParksView === 'map' ? defaultProjectsForMapView : itemsPerPage
    );
    navigate(`${location.pathname}${inputQuery || queryString}`, {}).then(
      () => {
        if (dataSectionRef.current) {
          dataSectionRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    );
  };

  const prepareFilterMasterParams = params => {
    let filters = [];
    let filterMasterParams = {};
    if (params) {
      if (Object.keys(params).find(key => key === 'Favorites')) {
        const val = {
          Name: 'Favorites',
          Value: JSON.parse(
            localStorage.getItem(
              `${data.node.programCode ? data.node.programCode : ''}Favorites`
            )
          ),
        };
        filters.push(val);
      } else {
        Object.keys(params).map(function(key) {
          let val = {};
          if (key === 'search') {
            val = {
              Name: key,
              Value: (params[key] || [''])[0],
            };
          } else {
            if (params[key].length > 1) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: params[key].map(Number),
              };
            } else if (params[key].length > 0) {
              val = {
                Name: key === 'InvestmentType' ? 'PROJECTOPPORTUNITYTYPE' : key,
                Value: [parseInt(params[key])],
              };
            }
          }
          filters.push(val);
        });
      }

      filterMasterParams = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortBy: sortBy,
        SortOrder: sortOrder,
        Filters: filters,
      };
    }
    return filterMasterParams;
  };

  const setFilterValues = params => {
    const filterMap = {
      Country: setCountries,
      // TotalInvestmentCost: setCostRange,
      Sector: setSectors,
      updated: () => {},
      InvestmentType: setInvestmentTypes,
      sdg: setSdg,
      search: setSearchString,
      Source: setSource,
    };

    Object.keys(params).map(key => {
      const setFilter = filterMap[key] || (() => {});
      key === 'search'
        ? setFilter((params[key] || [''])[0])
        : setFilter(params[key].filter(e => e != '').map(val => parseInt(val)));
    });
  };

  const updateStateElement = (
    stateCollection,
    updateState,
    updateChange
  ) => value => () => {
    const collection = stateCollection || [];

    const currentIndex = (collection || []).indexOf(value);
    const updatedState =
      currentIndex === -1
        ? [...collection, value]
        : collection
            .slice(0, currentIndex)
            .concat(collection.slice(currentIndex + 1));

    updateState(updatedState);
    updateChange(true);
  };

  const handleCountriesChange = updateStateElement(
    countries,
    setCountries,
    setCountryApplied
  );

  // const handleSectorsChange = updateStateElement(sectors, setSectors, setSectorApplied);

  // const handleSourceChange = updateStateElement(source, setSource);

  const changeProjectView = view => () => {
    setPageNumber(0);
    setPageSize(view === 'map' ? defaultProjectsForMapView : itemsPerPage);
    setIndustrialParksView(view);
  };

  const updateFilter = setFilter => value => {
    setFilteredSEZs([]);
    setFilter && setFilter(value);
  };

  // const handleDialogClose = () => {
  //   setOpenDialog(false);
  // };

  // const handleFilterTabChange = (event, newValue) => {
  //   setFilterTab(newValue);
  // };

  const handleExpandChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Infinite Scroll Functionality
  // const increasePageSize = (size = null) => {
  //   if (pageSize > 9 && (filteredSEZs || {}).length < 9) {
  //     return;
  //   }
  //   setPageSize(size ? size : pageSize + 3);
  // };

  // Search Functionality
  const onIndustrialParksSearch = action => {
    action
      ? searchString.length === 0
        ? location.href &&
          location.href.indexOf('?') > 0 &&
          filterClick('clearSearch')()
        : searchString.length > 2 && filterClick('filter')()
      : location.href &&
        location.href.indexOf('?') > 0 &&
        filterClick('clearSearch')();
  };

  // Filter Expansion panel

  const handleExpansionClick = panel => (event, isExpanded) => {
    setFilterSectionExpand(isExpanded ? panel : false);
  };

  const syncOfflineIndustrialParks = industrialParks => {
    const industrialParkSync = [];

    industrialParkSync.push(...industrialParks);

    industrialParkSync.push(
      ...(offlineIndustrialParks || []).filter(
        industrialPark =>
          !(industrialParks || []).find(
            p => p.industrialParkID === industrialPark.industrialParkID
          )
      )
    );

    if (
      industrialParks.length < pageSize &&
      (location.href || {}).indexOf('?') < 0
    ) {
      setOfflineIndustrialParks(industrialParks);
    } else {
      setOfflineIndustrialParks(industrialParkSync);
    }
  };
  // const syncOfflineProjects = projects => {
  //   const projectsSync = [];

  //   projectsSync.push(...projects);

  //   projectsSync.push(
  //     ...(offlineProjects || []).filter(
  //       project =>
  //         !(projects || []).find(p => p.projectID === project.projectID)
  //     )
  //   );

  //   if (projects.length < pageSize && (location.href || {}).indexOf('?') < 0) {
  //     setOfflineProjects(projects);
  //     syncFavorites(projects);
  //   } else {
  //     setOfflineProjects(projectsSync);

  //     if (
  //       isFavoritesView &&
  //       projects.length < pageSize &&
  //       projects.length < favoriteProjects.length
  //     ) {
  //       syncFavorites(projects);
  //     }
  //   }

  //   // setOfflineProjects([...new Set([...offlineProjects, ...projects]
  //   //       .map(project => JSON.stringify(project)))]
  //   //       .map(project => JSON.parse(project)));
  // };

  // const syncFavorites = projects => {
  //   setFavoriteProjects(
  //     favoriteProjects.filter(projectID =>
  //       projects.find(project => project.projectID === projectID)
  //     )
  //   );
  // };

  return (
    <Layout
      programLink={programLink}
      title={t('industrial_parks')}
      content={[t('industrial_parks_sub_1')]}
      imageName={'bg-sez.jpg'}
      headerLinks={headerLinks}
      logos={logos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'industrial_parks' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Industrial Parks')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>

      <div className="container">
        <GridContainer
          style={{ marginBottom: '40px' }}
          className="projects-container"
        >
          <GridContainer>
            <GridItem md={12} style={{ marginBottom: '40px' }}>
              <Accordion
                className="expanded-accordion"
                expanded={expanded === 'panel1'}
                onChange={handleExpandChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1"
                  id="panel1"
                >
                  <Typography className="accordion-title">Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GridContainer>
                    <GridItem className="search-bar-filter" md={12}>
                      <ProjectsSearch
                        onProjectsSearch={onIndustrialParksSearch}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        placeholder={t('search_industrial_parks')}
                      />
                    </GridItem>
                    <GridContainer>
                      <GridItem md={6}>
                        <div className="filters-container">
                          <ExpansionPanel
                            expanded={filterSectionExpand === 'country'}
                            onChange={handleExpansionClick('country')}
                            className={styleClasses.panel}
                          >
                            <ExpansionPanelSummary
                              className={styleClasses.summary}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={styleClasses.heading}>
                                {t('Country')}
                                {countries.length ? (
                                  <ClearFilterSection
                                    handleClick={() => {
                                      setCountries([]);
                                      setClearFilterSection(true);
                                    }}
                                  />
                                ) : (
                                  ''
                                )}
                                {countryApplied ? (
                                  <ApplyFilterSection
                                    handleClick={filterClick('filter')}
                                    updateElement={setCountryApplied}
                                  />
                                ) : (
                                  ''
                                )}
                              </Typography>
                            </ExpansionPanelSummary>

                            <ExpansionPanelDetails
                              className={styleClasses.contents}
                            >
                              <List>
                                {filterMasterData &&
                                  (filterMasterData.COUNTRY || []).map(
                                    value => {
                                      return (
                                        <ListItem key={value.countryID}>
                                          <Checkbox
                                            color="primary"
                                            className={styleClasses.checkbox}
                                            edge="start"
                                            checked={
                                              countries.indexOf(
                                                value.countryID
                                              ) !== -1
                                            }
                                            onChange={handleCountriesChange(
                                              value.countryID
                                            )}
                                            tabIndex={-1}
                                            disableRipple
                                            value={value.countryID}
                                          ></Checkbox>
                                          <p css={filterItem}>
                                            {value.countryName}
                                          </p>
                                        </ListItem>
                                      );
                                    }
                                  )}
                              </List>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem md={6}>
                        <div className="filter-actions">
                          <button
                            onClick={filterClick('filter')}
                            className="submit-button"
                            disabled={
                              !countries.length &&
                              !sectors.length &&
                              !sdg.length &&
                              !investmentTypes.length &&
                              searchString.length < 3 &&
                              !isCostRangeUpdated &&
                              !source.length
                            }
                          >
                            {t('Submit')}
                          </button>
                        </div>
                      </GridItem>
                      <GridItem md={6}>
                        <div className="filter-actions">
                          <button
                            onClick={filterClick('clear')}
                            className="submit-button"
                            disabled={
                              !(location.href && location.href.indexOf('?') > 0)
                            }
                          >
                            {t('Clear')}
                          </button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                  {/* {!mobileScreen ? (
                <GridContainer>
                  <GridItem className="search-bar-filter" md={12}>
                    <ProjectsSearch
                      onProjectsSearch={onIndustrialParksSearch}
                      searchString={searchString}
                      setSearchString={setSearchString}
                      placeholder={t('search_industrial_parks')}
                    />
                  </GridItem>
                  <GridContainer>
                    <GridItem md={6}>
                      <div className="filters-container">
                        <ExpansionPanel
                          expanded={filterSectionExpand === 'country'}
                          onChange={handleExpansionClick('country')}
                          className={styleClasses.panel}
                        >
                          <ExpansionPanelSummary
                            className={styleClasses.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={styleClasses.heading}>
                              {t('Country')}
                              {countries.length ? (
                                <ClearFilterSection
                                  handleClick={() => {
                                    setCountries([]);
                                    setClearFilterSection(true);
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              {countryApplied ? (
                                <ApplyFilterSection
                                  handleClick={filterClick('filter')}
                                  updateElement={setCountryApplied}
                                />
                              ) : (
                                ''
                              )}
                            </Typography>
                          </ExpansionPanelSummary>

                          <ExpansionPanelDetails className={styleClasses.contents}>
                            <List>
                              {filterMasterData &&
                                (filterMasterData.COUNTRY || []).map(value => {
                                  return (
                                    <ListItem key={value.countryID}>
                                      <Checkbox
                                        color="primary"
                                        className={styleClasses.checkbox}
                                        edge="start"
                                        checked={
                                          countries.indexOf(value.countryID) !== -1
                                        }
                                        onChange={handleCountriesChange(
                                          value.countryID
                                        )}
                                        tabIndex={-1}
                                        disableRipple
                                        value={value.countryID}
                                      ></Checkbox>
                                      <p css={filterItem}>{value.countryName}</p>
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <hr className="horizontal-bar-filter"/>
                  <GridContainer>
                      <GridItem md={6}>
                        <div className="filter-actions">
                          <button
                              onClick={filterClick('filter')}
                              className="submit-button"
                              disabled={
                                !countries.length &&
                                !sectors.length &&
                                !sdg.length &&
                                !investmentTypes.length &&
                                searchString.length < 3 &&
                                !isCostRangeUpdated &&
                                !source.length
                              }
                            >
                              {t('Submit')}
                            </button>
                        </div>
                      </GridItem>
                      <GridItem md={6}>
                        <div className="filter-actions">
                          <button
                            onClick={filterClick('clear')}
                            className="submit-button"
                            disabled={!(location.href && location.href.indexOf('?') > 0)}
                          >
                            {t('Clear')}
                          </button>
                        </div>
                      </GridItem>
                  </GridContainer>
                </GridContainer>
              ) : (
                <>
                <GridContainer>
                  <GridItem md={12}>
                  <ProjectsSearch
                    onProjectsSearch={onIndustrialParksSearch}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    placeholder={t('search_industrial_parks')}
                  />
                  <Button color="inherit" onClick={() => setOpenDialog(true)}>
                    <FilterList />
                    <FilterHeading>{t('Filter')}</FilterHeading>
                  </Button>
                  <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={handleDialogClose}
                    TransitionComponent={Transition}
                    css={MobileFilter}
                  >
                    <AppBar className={styleClasses.appBar}>
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={styleClasses.title}>
                          {t('Filter')}
                        </Typography>
                        <Button
                          color="inherit"
                          onClick={filterClick('filter')}
                          disabled={
                            !countries.length &&
                            !sectors.length &&
                            !sdg.length &&
                            !investmentTypes.length &&
                            searchString.length < 3 &&
                            !isCostRangeUpdated
                          }
                        >
                          {t('Submit')}
                        </Button>
                      </Toolbar>
                    </AppBar>
                    <div className={styleClasses.root}>
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={filterTab}
                        onChange={handleFilterTabChange}
                        aria-label="Vertical tabs example"
                        className={styleClasses.tabs}
                      >
                        <Tab
                          className={styleClasses.tabItem}
                          label={t('Source')}
                          {...filterTabs(0)}
                        />
                        <Tab
                          className={styleClasses.tabItem}
                          label={t('Country')}
                          {...filterTabs(1)}
                        />
                      </Tabs>

                      <TabPanel
                        value={filterTab}
                        index={0}
                        className={styleClasses.tabsPanel}
                      >
                        <div className={styleClasses.contentsMobile}>
                          <List>
                            {filterMasterData &&
                              (filterMasterData.SOURCE || []).map(value => {
                                return (
                                  <ListItem key={value.campaignSourceID}>
                                    <Checkbox
                                      color="primary"
                                      className={styleClasses.checkbox}
                                      edge="start"
                                      checked={
                                        source.indexOf(
                                          value.campaignSourceID
                                        ) !== -1
                                      }
                                      onChange={handleSourceChange(
                                        value.campaignSourceID
                                      )}
                                      tabIndex={-1}
                                      disableRipple
                                      value={value.campaignSourceID}
                                    ></Checkbox>
                                    <p css={filterItem}>
                                      {value.campaignSourceName}
                                    </p>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </div>
                      </TabPanel>
                      <TabPanel
                        value={filterTab}
                        index={1}
                        className={styleClasses.tabsPanel}
                      >
                        <div className={styleClasses.contentsMobile}>
                          <List>
                            {filterMasterData &&
                              filterMasterData.COUNTRY &&
                              filterMasterData.COUNTRY.map(value => {
                                return (
                                  <ListItem key={value.countryID}>
                                    <Checkbox
                                      color="primary"
                                      className={styleClasses.checkbox}
                                      edge="start"
                                      checked={
                                        countries.indexOf(value.countryID) !==
                                        -1
                                      }
                                      onChange={handleCountriesChange(
                                        value.countryID
                                      )}
                                      tabIndex={-1}
                                      disableRipple
                                      value={value.countryID}
                                    ></Checkbox>
                                    <p>{value.countryName}</p>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </div>
                      </TabPanel>
                    </div>
                  </Dialog>
                  {
                    {
                      false: null,
                      true: (
                        <button
                          onClick={filterClick('clear')}
                          css={[buttonSubmit, buttonClear]}
                          color="inherit"
                        >
                          {t('Clear')}
                        </button>
                      ),
                    }[location.href && location.href.indexOf('?') > 0]
                  }
                  </GridItem>
                </GridContainer>
                </>
              )} */}
                </AccordionDetails>
              </Accordion>
            </GridItem>
            <GridItem md={12}>
              <GridContainer className="project-top">
                {/* <GridItem md={12} className="filter-text"> */}
                <GridItem md={7} xs={12} className="filter-text">
                  {
                    {
                      true: null,
                      false:
                        sezCount > 0 ||
                        Object.keys(searchCriteria).length > 0 ? (
                          <FilterText
                            filterMasterData={filterMasterData}
                            searchCriteria={searchCriteria}
                            totalProjectCount={sezCount}
                            currentProjectCount={
                              (filteredSEZs.filter(sez => !sez.isPartner) || {})
                                .length
                            }
                            location={location}
                            navigate={navigate}
                            filterClick={filterClick('clear')}
                            placeholderSingle={t('industrial_park')}
                            placeholder={t('industrial_parks')}
                          />
                        ) : (
                          ''
                        ),
                    }[isProcessing]
                  }
                </GridItem>

                <GridItem md={5} xs={12} className="project-view">
                  <div ref={dataSectionRef}>
                    <Tooltip title={t('grid_view')} placement="top">
                      <IconButton
                        edge="start"
                        color={
                          industrialParksView === 'grid' ? 'primary' : 'default'
                        }
                        onClick={changeProjectView('grid')}
                        aria-label="grid"
                      >
                        <GridOn />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('list_view')} placement="top">
                      <IconButton
                        edge="start"
                        color={
                          industrialParksView === 'list' ? 'primary' : 'default'
                        }
                        onClick={changeProjectView('list')}
                        aria-label="list"
                      >
                        <ViewList />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t('map_view')} placement="top">
                      <IconButton
                        edge="start"
                        color={
                          industrialParksView === 'map' ? 'primary' : 'default'
                        }
                        onClick={changeProjectView('map')}
                        aria-label="map"
                      >
                        <LocationOn />
                      </IconButton>
                    </Tooltip>
                  </div>
                </GridItem>
                {/* </GridItem> */}
              </GridContainer>
              {
                {
                  grid: (
                    <div>
                      {filteredSEZs ? (
                        <GridView
                          totalProjects={
                            (filteredSEZs.filter(sez => !sez.isPartner) || {})
                              .length
                          }
                          gridData={filteredSEZs}
                          onPageNumberChange={updateFilter(setPageNumber)}
                          onPageSizeChange={updateFilter(setPageSize)}
                          fallback={<GridSkeleton />}
                          isLoading={isProcessing}
                          programLink={programLink}
                          programCode={programName}
                          pageNumber={pageNumber}
                          pageSize={pageSize}
                        />
                      ) : (
                        <Container width="100%">
                          <div className="three-dots-loader">
                            <ThreeDots
                              color="#5990ff"
                              height={100}
                              width={100}
                              timeout={10000}
                            />
                          </div>
                        </Container>
                      )}
                    </div>
                  ),
                  list: (
                    <div>
                      {filteredSEZs ? (
                        <Table
                          tableHeaderColor="warning"
                          tableHead={[
                            t('Name'),
                            t('Description'),
                            t('Location'),
                          ]}
                          tableData={filteredSEZs}
                          totalProjects={
                            (filteredSEZs.filter(sez => !sez.isPartner) || {})
                              .length
                          }
                          onPageNumberChange={updateFilter(setPageNumber)}
                          onPageSizeChange={updateFilter(setPageSize)}
                          onSortByChange={updateFilter(setSortBy)}
                          onSortOrderChange={updateFilter(setSortOrder)}
                          fallback={
                            <div style={{ lineHeight: 3 }}>
                              {[1, 2, 3, 4, 5, 6].map(num => (
                                <Skeleton count={10} key={num} height={10} />
                              ))}
                            </div>
                          }
                          isLoading={isProcessing}
                          programLink={programLink}
                          programCode={
                            data.node.programCode ? data.node.programCode : ''
                          }
                          pageNumber={pageNumber}
                          pageSize={pageSize}
                        />
                      ) : (
                        <Container width="100%">
                          <div className="three-dots-loader">
                            <ThreeDots
                              color="#5990ff"
                              height={100}
                              width={100}
                              timeout={10000}
                            />
                          </div>
                        </Container>
                      )}
                    </div>
                  ),
                  map: (
                    <>
                      <span>{t('show_partners')}: </span>
                      <Switch
                        checked={showPartners}
                        onChange={e => setShowPartners(e.target.checked)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      {/* leaflet map view */}
                      {/* <MapView
                        style={{ width: '100%' }}
                        industrialParks={filteredSEZs}
                        zoomControl={true}
                        markerText={'Hello, this is a marker'}
                        fallback={
                          <GridItem xs={12} sm={12} md={12}>
                            <Skeleton height={!mobileScreen ? 500 : 100} />
                          </GridItem>
                        }
                        isLoading={isProcessing}
                        programLink={programLink}
                        programCode={
                          data.node.programCode ? data.node.programCode : ''
                        }
                        handleFavoriteProjects={() => {}}
                        favoriteProjects={[]}
                      /> */}
                      {/* Google map view */}
                      <MapWrapper
                        style={{ width: '100%' }}
                        industrialParks={filteredSEZs}
                        zoomControl={true}
                        markerText={'Hello, this is a marker'}
                        fallback={
                          <GridItem xs={12} sm={12} md={12}>
                            <Skeleton height={!mobileScreen ? 500 : 100} />
                          </GridItem>
                        }
                        isLoading={
                          isProcessing ||
                          isIndustrialParksProcessing ||
                          isInstitutionsProcessing
                        }
                        programLink={programLink}
                        programCode={
                          data.node.programCode ? data.node.programCode : ''
                        }
                        handleFavoriteProjects={() => {}}
                        favoriteProjects={[]}
                        clusterPage={true}
                        pageTypes={{
                          industrials: true,
                          partners: false,
                          projects: false,
                          countries: false,
                        }}
                      />
                    </>
                  ),
                }[industrialParksView]
              }
            </GridItem>
          </GridContainer>
        </GridContainer>
      </div>
    </Layout>
  );
};

export default withStyles(dashboardStyle)(IndustrialParks);

const GridSkeleton = () => {
  const gridArray = Array.from(Array(4).keys());
  return (
    <GridSkeletonWrapper>
      {gridArray.map(e => {
        return (
          <GridItem xs={12} sm={3} md={3} xl={3} key={e}>
            <Skeleton height={200} />
          </GridItem>
        );
      })}
    </GridSkeletonWrapper>
  );
};

const ClearFilterSection = ({ handleClick }) => {
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      control={
        <IconButton
          size="small"
          color="inherit"
          onClick={handleClick}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

const ApplyFilterSection = ({ handleClick, updateElement }) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={() => (updateElement ? updateElement(false) : '')}
      onFocus={event => event.stopPropagation()}
      control={
        <button css={buttonApply} onClick={handleClick}>
          {t('apply')}
        </button>
      }
    />
  );
};

ClearFilterSection.propTypes = {
  handleClick: PropTypes.func,
};

ApplyFilterSection.propTypes = {
  handleClick: PropTypes.func,
  updateElement: PropTypes.func,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

IndustrialParks.propTypes = {
  classes: PropTypes.any,
  location: PropTypes.object,
  navigate: PropTypes.func,
  pageContext: PropTypes.any,
};

function filterTabs(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
